var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-author"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"list-author col-md-12 d-flex"},_vm._l((_vm.optionAuthors),function(value,index){return _c('div',{key:index,staticClass:"author-item col-md-3 col-12 d-flex flex-column align-items-center"},[_c('router-link',{attrs:{"to":{
            name: _vm.$route.params.shopId
              ? 'author detail'
              : 'author detail domain',
            params: { id: value.id },
          }}},[_c('div',{staticClass:"imageBox"},[(value.image_name)?_c('img',{staticClass:"authorImage",attrs:{"src":`${_vm.urlBackend}/${value.image_name}`}}):_c('img',{staticClass:"authorImage",attrs:{"src":`${_vm.author_image}`}})]),_c('p',{staticClass:"text-center font-weight-bold my-2 text-name"},[_vm._v(" "+_vm._s(value.nickname)+" ")])])],1)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 col-12"},[_c('div',{staticClass:"title"},[_vm._v("著者リスト")])])
}]

export { render, staticRenderFns }