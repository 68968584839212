<template>
  <div class="store-author">
    <div class="row">
      <div class="col-md-12 col-12"><div class="title">著者リスト</div></div>
      <div class="list-author col-md-12 d-flex">
        <div
          class="author-item col-md-3 col-12 d-flex flex-column align-items-center"
          v-for="(value, index) in optionAuthors"
          :key="index"
        >
          <router-link
            :to="{
              name: $route.params.shopId
                ? 'author detail'
                : 'author detail domain',
              params: { id: value.id },
            }"
          >
            <div class="imageBox">
              <img
                class="authorImage"
                v-if="value.image_name"
                :src="`${urlBackend}/${value.image_name}`"
              />
              <img class="authorImage" v-else :src="`${author_image}`" />
            </div>
            <p class="text-center font-weight-bold my-2 text-name">
              {{ value.nickname }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import author_image from "@/assets/img/author.jpg";
export default {
  name: "StoreAuthor",
  data() {
    return {
      optionAuthors: [],
      author_image: author_image,
    };
  },
  created() {
    this.getListAuthorInShop();
  },

  watch: {
    listAuthor() {
      this.optionAuthors = this.listAuthor.map((item) => ({
        id: item.id,
        nickname: item.nick_name,
      }));
    },
  },
  computed: {
    ...mapGetters(["listAuthor"]),
  },

  methods: {
    ...mapActions({ getListAuthorInShop: "getListAuthorInShop" }),
  },
};
</script>
